import { useEffect, useState, useRef } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonLabel,
  IonButton,
  IonSpinner,
  IonIcon,
  IonInput,
  IonAvatar,
} from '@ionic/react';
import { pencil, checkmark } from 'ionicons/icons';
import axios from 'axios';

interface User {
  id: string;
  email: string;
  username?: string;
  profileImage?: string;
}

const UserProfile: React.FC = () => {
  const ownerId = localStorage.getItem('id') || '';
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingField, setEditingField] = useState<string | null>(null);
  const inputRef = useRef<HTMLIonInputElement | null>(null);
  const [profileImage, setProfileImage] = useState<File | null>(null);

  // Centraliza el token
  const token = localStorage.getItem('token');

  // Función para obtener datos del perfil del usuario
  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!token) {
        setError('No se encontró un token de autenticación.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get<User>(`https://misumisu.eu/usuarios/${ownerId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
      } catch (err: any) {
        console.error(err);
        setError('Error al obtener el perfil del usuario.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [ownerId, token]);

  // Función para manejar el cambio de imagen
  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return;
    const file = event.target.files[0];
    setProfileImage(file);

    const formData = new FormData();
    formData.append('profileImage', file);

    try {
      const response = await axios.put<User>(
        `https://misumisu.eu/usuarios/${ownerId}/profile-image`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setUser({ ...user!, profileImage: response.data.profileImage });
      alert('Imagen de perfil actualizada con éxito.');
    } catch (error) {
      console.error('Error al actualizar la imagen de perfil:', error);
      setError('Error al actualizar la imagen. Intenta nuevamente.');
    }
  };

  // Función para habilitar edición del username
  const handleEditClick = () => {
    setEditingField('username');
    inputRef.current?.setFocus();
  };

  // Función para guardar el username
  const handleSaveClick = async () => {
    if (!token) return;

    try {
      await axios.put(
        `https://misumisu.eu/usuarios/${ownerId}`,
        { username: user?.username },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setEditingField(null);
    } catch (error) {
      console.error('Error al actualizar los datos del usuario:', error);
      setError('Error al actualizar el perfil. Intenta nuevamente.');
    }
  };

  // Función para manejar cambios en el input de username
  const handleInputChange = (value: string) => {
    setUser({ ...user!, username: value });
  };

  // Estilos para avatar y contenedor
  const styles = {
    avatarContainer: {
      display: 'flex' as const, // `as const` asegura que el valor sea aceptado
      flexDirection: 'column' as const,
      alignItems: 'center' as const,
      marginBottom: '20px',
    },
    avatar: {
      width: '150px',
      height: '150px',
      borderRadius: '50%',
      overflow: 'hidden',
    },
  };
  

  if (loading) {
    return (
      <IonContent>
        <IonSpinner name="crescent" />
      </IonContent>
    );
  }

  // Render principal
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Perfil</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {error ? (
          <p>{error}</p>
        ) : (
          user && (
            <IonCard style={{ padding: '20px' }}>
              <IonCardContent>
                {/* Imagen de perfil */}
                <div style={styles.avatarContainer}>
                  <IonAvatar style={styles.avatar}>
                    {profileImage ? (
                      <img src={URL.createObjectURL(profileImage)} alt="Foto de perfil" />
                    ) : user.profileImage ? (
                      <img src={user.profileImage} alt="Foto de perfil" />
                    ) : (
                      <img
                        src="https://via.placeholder.com/150/cccccc/808080/?text=Usuario"
                        alt="Silueta de perfil"
                      />
                    )}
                  </IonAvatar>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ marginTop: '10px' }}
                  />
                </div>

                {/* Username */}
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 2fr auto',
                    alignItems: 'center',
                    gap: '10px',
                    marginBottom: '15px',
                  }}
                >
                  <IonLabel>
                    <strong>Username:</strong>
                  </IonLabel>
                  {editingField === 'username' ? (
                    <IonInput
                      ref={(el) => (inputRef.current = el)}
                      value={user.username}
                      onIonChange={(e) => handleInputChange(e.detail.value!)}
                      style={{
                        borderBottom: '2px solid blue',
                        paddingBottom: '4px',
                      }}
                    />
                  ) : (
                    <IonLabel>{user.username}</IonLabel>
                  )}
                  <IonButton
                    fill="clear"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      editingField === 'username' ? handleSaveClick() : handleEditClick();
                    }}
                  >
                    <IonIcon icon={editingField === 'username' ? checkmark : pencil} />
                  </IonButton>
                </div>

                <IonButton expand="full" color="primary" href="/home" style={{ marginTop: '20px' }}>
                  Volver
                </IonButton>
              </IonCardContent>
            </IonCard>
          )
        )}
      </IonContent>
    </IonPage>
  );
};

export default UserProfile;
