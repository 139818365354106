import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
  IonImg,
  IonCardSubtitle,
  IonCardContent,
} from '@ionic/react';

import { format } from 'date-fns';
import { add, chatbubbles, paperPlane, personCircle, paw, informationCircle} from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import AddCardForm from './AddCardForm';
import MessagesInbox from './MessagesInbox';
import Chat from '../components/Chat';
import socket from './socket';
import './Home.css';


interface HomeProps {
  onLogout: () => void;
  isAuthenticated: boolean;
}

interface Card {
  id: number;
  title: string;
  subtitle: string;
  content: string;
  color: string;
  imageUrl: string;
  id_owner: string;
  deadline: string; // Asegúrate de que deadline esté incluido en la interfaz
  location: 'home' | 'outside'; // Ubicación del favor (en casa o fuera)
}

interface ChatItem {
  id: number;
  user1_id: string;
  user2_id: string;
  created_at: string;
  lastMessage?: string;
  lastMessageTime?: string;
  unread?: boolean;
  card_title: string;
  has_unread?: boolean;
}

const Home: React.FC<HomeProps> = ({ onLogout, isAuthenticated }) => {
  const history = useHistory();
  const [cards, setCards] = useState<Card[]>([]);
  const [showAddCardForm, setShowAddCardForm] = useState(false);
  const [showMessagesInbox, setShowMessagesInbox] = useState(false);
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const [showCardModal, setShowCardModal] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [chatId, setChatId] = useState<string | null>(null);
  const [receiverId, setReceiverId] = useState<string | null>(null);
  const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false);

  const currentUserId = localStorage.getItem('id') || '';
  const token = localStorage.getItem('token') || ''; // Obtener el token almacenado


  // Función para verificar el token
  const verifyToken = async () => {
    try {
      const response = await fetch('https://misumisu.eu/comprobartoken', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 403) {
        console.error('Token inválido o expirado. Cerrando sesión...');
        localStorage.clear(); // Limpiar el almacenamiento local
        onLogout(); // Llama a la función de logout
        history.push('/home'); // Redirige al usuario a la página de inicio
      }
    } catch (error) {
      console.error('Error al verificar el token:', error);
    }
  };

  // Verificar el token al cargar el componente
  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await fetch('https://misumisu.eu/tarjetas');
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error('Error al cargar tarjetas:', error);
      }
    };
    fetchCards();
  }, []);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await fetch(`https://misumisu.eu/chats/${currentUserId}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Agregar el token al encabezado
          },
        });
        if (response.ok) {
          const data = await response.json();
  
          // Verificar si data es un array antes de usar .some()
          if (Array.isArray(data)) {
            const unreadChats = data.some(chat => chat.has_unread);
            setHasUnreadMessages(unreadChats);
          } else {
            // Si no es un array, significa que no hay chats
            console.log(data.message || 'No se encontraron chats para este usuario.');
            setHasUnreadMessages(false);
          }
        }
      } catch (error) {
        console.error('Error al obtener los chats:', error);
      }
    };
  
    if (currentUserId) {
      fetchChats();
    }
  }, [currentUserId]);
  

  useEffect(() => {
    const handleReceiveMessage = (message: { chatId: number }) => {
      if (message.chatId !== parseInt(chatId ?? '-1', 10)) {
        setHasUnreadMessages(true);
      }
    };

    if (socket) {
      socket.on('receive_message', handleReceiveMessage);
    }

    return () => {
      if (socket) {
        socket.off('receive_message', handleReceiveMessage);
      }
    };
  }, [socket, chatId]);

  // Redirige al login si el usuario no está autenticado
  const requireLogin = (callback: () => void) => {
    if (!isAuthenticated) {
      history.push('/login');
    } else {
      callback();
    }
  };

  const openAddCardForm = () => {
    requireLogin(() => setShowAddCardForm(true));
  };

  const openMessagesInbox = () => {
    requireLogin(() => setShowMessagesInbox(true));
  };

  const openUserProfile = () => {
    requireLogin(() => history.push('/my-interface'));
  };
  
  

  const openCardModal = (card: Card) => {
    setSelectedCard(card);
    setShowCardModal(true);
  };

  const handleOpenChat = async (card: Card) => {
    requireLogin(async () => {
      if (card.id_owner !== currentUserId) {
        try {
          const response = await fetch(`https://misumisu.eu/chats/find/${currentUserId}/${card.id_owner}/${card.id}`, {
            headers: {
              'Authorization': `Bearer ${token}`, // Agregar el token al encabezado
            },
          });
          const data = await response.json();

          if (data.chatId) {
            setChatId(data.chatId);
          } else {
            const createChatResponse = await fetch(`https://misumisu.eu/chats/create`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify({ user1_id: currentUserId, user2_id: card.id_owner, card_id: card.id }),
            });

            const newChatData = await createChatResponse.json();
            setChatId(newChatData.chatId);
          }
          setReceiverId(card.id_owner);
          setShowChat(true);
        } catch (error) {
          console.error('Error al abrir el chat:', error);
        }
      }
    });
  };


  const handleViewProfile = () => {
    if (selectedCard) {
      setShowCardModal(false);  // Cierra el modal de la tarjeta
    }
    history.push('/user-profile');
  };

  const handleViewPetProfile = () => {
    if (selectedCard) {
      setShowCardModal(false);  // Cierra el modal de la tarjeta
    }
    history.push('/pet-profile');
  };

  const handleViewCartProfile = (ownerId: string) => {
    if (selectedCard) {
      setShowCardModal(false);  // Cierra el modal de la tarjeta
    }
    history.push(`/profileCart/${ownerId}`);
  };

  const handleViewCartPetProfile = (ownerId: string) => {
    if (selectedCard) {
      setShowCardModal(false);  // Cierra el modal de la tarjeta
    }
    history.push(`/mascotas/${ownerId}`);
  };

// Nueva función para navegar a HerInterface
const handleViewHerInterface = () => {
  if (selectedCard) {
    setShowCardModal(false);
    history.push(`/usuarios/${selectedCard.id_owner}`);  // Usamos selectedCard.owner_id
  }
};

  const handleAddCard = async (
  title: string,
  subtitle: string,
  content: string,
  imageFile: File | null,
  owner_id: string,
  deadline: string,
  location: string
) => {
  try {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('subtitle', subtitle);
    formData.append('content', content);
    formData.append('owner_id', owner_id);
    formData.append('deadline', deadline);
    formData.append('location', location);

    if (imageFile) {
      formData.append('image', imageFile);
    }

    console.log({
      title,
      subtitle,
      content,
      imageFile,
      owner_id,
      deadline,
      location
    });
    

    const response = await fetch('https://misumisu.eu/tarjetas', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('No se pudo agregar la tarjeta');
    }

    const newCard = await response.json();
    setCards((prevCards) => [...prevCards, newCard]);
    setShowAddCardForm(false);
  } catch (error) {
    console.error('Error al agregar tarjeta:', error);
  }
};




  

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Home</IonTitle>
          {isAuthenticated && (
            <IonButtons slot="end">
              <IonButton onClick={onLogout}>Cerrar Sesión</IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
      <div className="card-container">
        {cards.length > 0 ? (
          cards.map((card) => {
            return (
              <IonCard
                key={card.id}
                className="custom-card"
                style={{ backgroundImage: `url(https://misumisu.eu${card.imageUrl})` }}
                onClick={() => openCardModal(card)}
              >
                <span className="card-type">
                  {card.location === 'home' ? 'En casa' : 'Fuera'}
                </span>
                <span className="card-date">
                  {format(new Date(card.deadline), 'dd/MM/yyyy')}
                </span>
                <div className="card-content">
                  <div className="card-title">{card.title}</div>
                  <p>{card.content}</p>
                </div>
              </IonCard>
            );
          })
        ) : (
          <p>No hay tarjetas disponibles</p>
        )}
      </div>


        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={openAddCardForm}>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>

        <IonFab vertical="bottom" horizontal="start" slot="fixed">
          <IonFabButton color="primary" onClick={openMessagesInbox}>
            <IonIcon icon={chatbubbles} />
            {hasUnreadMessages && <span className="unread-bubble">●</span>}
          </IonFabButton>
        </IonFab>


        <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton color="primary" onClick={openUserProfile}>
            <IonIcon icon={personCircle} />
          </IonFabButton>
        </IonFab>




        <IonModal isOpen={showCardModal} onDidDismiss={() => setShowCardModal(false)}>
  <IonContent>
    {selectedCard && (
      <IonCard>
        <IonImg src={selectedCard.imageUrl} alt="Imagen de la tarjeta" />
        <IonCardHeader>
          <IonCardTitle>{selectedCard.title}</IonCardTitle>
          <IonCardSubtitle>{selectedCard.subtitle}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <p>{selectedCard.content}</p>
          <p>Fecha límite: {new Date(selectedCard.deadline).toLocaleString()}</p>
          <p>Ubicación: {selectedCard.location === 'home' ? 'En casa' : 'Fuera'}</p>

          {/* Mostrar los botones solo si la tarjeta NO pertenece al usuario logeado */}
          {selectedCard.id_owner !== currentUserId && (
            <div className="profile-buttons-container">
              <IonButton color="secondary" onClick={handleViewHerInterface}>
                <IonIcon icon={personCircle} />
              </IonButton>
              
            </div>
          )}

          {/* Mostrar botón para iniciar chat solo si la tarjeta NO pertenece al usuario logeado */}
          {String(selectedCard.id_owner) !== String(currentUserId) && (
            <IonButton expand="block" onClick={() => handleOpenChat(selectedCard)}>
              <IonIcon icon={paperPlane} slot="start" />
              Iniciar Chat
            </IonButton>
          )}
        </IonCardContent>
        <IonButton onClick={() => setShowCardModal(false)}>Cerrar</IonButton>
      </IonCard>
    )}
  </IonContent>
</IonModal>


        <IonModal isOpen={showAddCardForm} onDidDismiss={() => setShowAddCardForm(false)}>
          <IonContent>
            <AddCardForm
              onAddCard={(title, subtitle, content, imageFile, id_owner, deadline, location) =>
                handleAddCard(title, subtitle, content, imageFile, id_owner, deadline, location)
              }
              
              onClose={() => setShowAddCardForm(false)}
            />
          </IonContent>
        </IonModal>




        <IonModal isOpen={showMessagesInbox} onDidDismiss={() => setShowMessagesInbox(false)}>
          <IonContent>
            <MessagesInbox currentUserId={currentUserId} socket={socket} onClose={() => setShowMessagesInbox(false)} />
          </IonContent>
        </IonModal>

        {showChat && chatId && receiverId && (
          <IonModal isOpen={showChat} onDidDismiss={() => setShowChat(false)}>
            <IonContent>
              <Chat chatId={chatId} receiverId={receiverId} currentUserId={currentUserId} socket={socket} onExit={() => setShowChat(false)} />
            </IonContent>
          </IonModal>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Home;
