import React, { useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';

interface AddCardFormProps {
  onAddCard: (title: string, subtitle: string, content: string, imageFile: File | null, id_owner: string, deadline: string, location: string) => void;
  onClose: () => void;
}

const AddCardForm: React.FC<AddCardFormProps> = ({ onAddCard, onClose }) => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [content, setContent] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [deadline, setDeadline] = useState(''); // Nueva fecha límite
  const [location, setLocation] = useState(''); // Nueva ubicación
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddCard = async () => {
    const id_owner = localStorage.getItem('id');
    if (!id_owner) {
      console.error('ID del dueño no encontrado en localStorage');
      return;
    }

    if (isSubmitting) return;
    setIsSubmitting(true);

    if (title && subtitle && content && imageFile && deadline && location) {
// Este es el `onAddCard` que llama al `handleAddCard` en `Home.tsx`
      onAddCard(title, subtitle, content, imageFile, id_owner, deadline, location);
      setTitle('');
      setSubtitle('');
      setContent('');
      setImageFile(null);
      setDeadline('');
      setLocation('');
      onClose();
    } else {
      console.error('Todos los campos son requeridos');
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <IonItem>
        <IonLabel position="floating">Card Title</IonLabel>
        <IonInput value={title} onIonChange={(e) => setTitle(e.detail.value!)} />
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Card Subtitle</IonLabel>
        <IonInput value={subtitle} onIonChange={(e) => setSubtitle(e.detail.value!)} />
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Card Content</IonLabel>
        <IonInput value={content} onIonChange={(e) => setContent(e.detail.value!)} />
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Image File</IonLabel>
        <input
          type="file"
          onChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
              setImageFile(e.target.files[0]);
            }
          }}
        />
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Deadline</IonLabel>
        <IonInput
            type="date"
            value={deadline}
            onIonChange={(e) => setDeadline(e.detail.value!)}
          />

      </IonItem>
      <IonItem>
        <IonLabel position="floating">Location</IonLabel>
        <IonSelect value={location} onIonChange={(e) => setLocation(e.detail.value!)}>
          <IonSelectOption value="home">En casa</IonSelectOption>
          <IonSelectOption value="outside">Fuera</IonSelectOption>
        </IonSelect>
      </IonItem>
      <IonButton expand="full" onClick={handleAddCard} disabled={isSubmitting}>
        {isSubmitting ? 'Adding...' : 'Add Card'}
      </IonButton>
      <IonButton expand="full" color="medium" onClick={onClose}>
        Cancel
      </IonButton>
    </>
  );
};

export default AddCardForm;
