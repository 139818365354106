import React, { useState, useEffect } from "react";
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonToast,
  IonAvatar
} from "@ionic/react";
import { useHistory } from 'react-router-dom';

interface CompleteProfileProps {
  onCompleteProfile: () => void; // Prop para notificar cuando se completa el perfil
}

const CompleteProfile: React.FC<CompleteProfileProps> = ({ onCompleteProfile }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState(localStorage.getItem('userEmail') || "");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  
  const history = useHistory();

  useEffect(() => {
    const storedEmail = localStorage.getItem("userEmail");
    console.log("Email del localStorage:", storedEmail); // Verificar el valor del correo
    if (!storedEmail) {
      history.push("/register");
    } else {
      setEmail(storedEmail);
    }
  }, [history]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setProfileImage(file);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log("Enviando datos de perfil:", { username, email });
  
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("username", username);
      if (profileImage) {
        formData.append("profileImage", profileImage);
      }

      const response = await fetch("https://misumisu.eu/complete-profile", {
        method: "POST",
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error("Error al guardar el perfil.");
      }
  
      const result = await response.json();
  
      console.log("Resultado del servidor:", result);
  
      // Guarda el id_owner en localStorage si está presente
      if (result.id_owner) {
        localStorage.setItem('firebaseUID', result.id_owner.toString()); // Usar firebaseUID en vez de id_owner o id
      } else {
        throw new Error("ID del dueño no encontrado en la respuesta del servidor.");
      }
  
      // Guardar que el perfil ha sido completado
      localStorage.setItem("isProfileComplete", "true");
  
      // Llamar a onCompleteProfile para actualizar el estado y redirigir al usuario
      onCompleteProfile();
  
    } catch (error) {
      console.error("Error al guardar el perfil:", error);
      setToastMessage("Hubo un error al guardar el perfil.");
      setShowToast(true);
    }
  };

  return (
    <IonPage>
      <IonContent style={styles.content}>
        <div style={styles.cardContainer}>
          <IonCard style={styles.card}>
            <IonCardHeader>
              <IonCardTitle>Completar Perfil</IonCardTitle>
            </IonCardHeader>
            <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
              {/* Avatar para foto de perfil */}
              <div style={styles.avatarContainer}>
                <IonAvatar style={styles.avatar}>
                  {profileImage ? (
                    <img src={URL.createObjectURL(profileImage)} alt="Foto de perfil" />
                  ) : (
                    <img src="https://via.placeholder.com/150/cccccc/808080/?text=Usuario" alt="Silueta de perfil" />
                  )}
                </IonAvatar>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ marginTop: '10px' }}
                />
              </div>

              {/* Nombre de Usuario */}
              <IonItem>
                <IonLabel position="floating">Nombre de Usuario</IonLabel>
                <IonInput
                  type="text"
                  value={username}
                  onIonChange={(e: any) => setUsername(e.target.value)}
                  required
                />
              </IonItem>

              <IonButton
                type="submit"
                expand="full"
                style={{ marginTop: "20px" }}
                color="primary"
              >
                Guardar Perfil
              </IonButton>
            </form>
          </IonCard>
        </div>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage || undefined}
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};

const styles = {
  content: {
    display: 'flex' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    minHeight: '100vh',
    backgroundColor: '#f5f5dc',
  },
  cardContainer: {
    display: 'flex' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    width: '100%',
    padding: '20px',
  },
  card: {
    width: '90%',
    maxWidth: '400px',
    textAlign: 'center' as const,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  avatarContainer: {
    display: 'flex' as const,
    flexDirection: 'column' as const,
    alignItems: 'center' as const,
    marginBottom: '20px',
  },
  avatar: {
    width: '100px',
    height: '100px',
  },
};

export default CompleteProfile;
