import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonImg,
  IonButton,
  IonFabButton,
  IonIcon,
} from '@ionic/react';
import { pencil } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './MyInterface.css';

interface Tarjeta {
  id: number;
  title: string;
  subtitle: string;
  content: string;
  color: string;
  imageUrl: string;
  fecha_creacion: string;
  deadline: string;
  location: string;
  id_owner: string;
}

const MyInterface: React.FC = () => {
  const [mascotas, setMascotas] = useState<any[]>([]);
  const [tarjetas, setTarjetas] = useState<Tarjeta[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [userProfileImage, setUserProfileImage] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);

  const token = localStorage.getItem('token');
  const ownerId = localStorage.getItem('id');
  const history = useHistory();


  useEffect(() => {
    const token = localStorage.getItem('token');
    const fetchUserData = async () => {
      const userId = localStorage.getItem('id');
      if (!userId) {
        setError('No se encontró el ID del usuario en localStorage');
        return;
      }

      try {
        const response = await fetch(`https://misumisu.eu/usuarios/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('No se pudo obtener los datos del usuario');
        }

        const data = await response.json();
        setUserProfileImage(data.profileImage || 'default-avatar.png');
        setUsername(data.username);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchMascotas = async () => {
      if (token && ownerId) {
        try {
          console.log("Fetching mascotas for ownerId:", ownerId); // Agrega logs para depurar
          const response = await axios.get(`https://misumisu.eu/mascotas2/owner/${ownerId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
    
          console.log("Mascotas response data:", response.data); // Verifica los datos recibidos
          if (Array.isArray(response.data) && response.data.length > 0) {
            setMascotas(response.data); // Actualiza el estado con los datos de las mascotas
          } else {
            setMascotas([]); // Si no hay mascotas, deja el array vacío
          }
        } catch (error) {
          setMascotas([]); // Si no hay mascotas, deja el array vacío
          console.error("Error al obtener las mascotas:", error); // Log detallado del error
        }
      }
    };
    

    const fetchTarjetas = async () => {
      if (token && ownerId) {
        try {
          const response = await axios.get('https://misumisu.eu/tarjetas2');
          if (Array.isArray(response.data)) {
            const tarjetasDelUsuario = response.data.filter((tarjeta: Tarjeta) =>
              String(tarjeta.id_owner) === String(ownerId)
            );
            setTarjetas(tarjetasDelUsuario);
          } else {
            setTarjetas([]); // Si no hay tarjetas, deja el array vacío
          }
        } catch (error) {
          setTarjetas([]); // Si no hay tarjetas, deja el array vacío
          console.error('Error al obtener las tarjetas:', error);
        }
      }
    };

    fetchUserData();
    fetchMascotas();
    fetchTarjetas();
    setLoading(false);
  }, [token, ownerId]);

  const handleGoToUserProfile = () => {
    history.push('/user-profile');
  };

  const handleGoToPetProfile = (petId: number) => {
    history.push(`/pet-profile/${petId}`);
  };

  if (loading) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Mi Interfaz</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonLabel>Cargando...</IonLabel>
        </IonContent>
      </IonPage>
    );
  }
  
  // Dentro del componente MyInterface
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Mi Interfaz</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {error && <IonLabel>{error}</IonLabel>}
  
        {userProfileImage && (
          <div className="profile-wrapper">
            <img
              src={userProfileImage}
              alt="Foto de perfil"
              className="profile-image"
              onError={(e) => (e.currentTarget.src = '/uploads/default-avatar.png')}
            />
          </div>
        )}
  
        {username && (
          <div className="user-info">
            <IonLabel className="profile-name">{username}</IonLabel>
          </div>
        )}
  
          {/* Mostrar las mascotas ordenadas */}
          <div style={{ marginTop: '30px', marginLeft: '25px' }}>
            <IonLabel>
              <strong>Sus Mascotas</strong>
            </IonLabel>
            {mascotas.length > 0 ? (
              <>
                <div
                  className="card-container"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', // Igual tamaño que las tarjetas
                    gap: '20px', // Espaciado entre tarjetas
                    marginTop: '20px',
                    marginLeft: '25px', // Margen izquierdo igual que "Mis Tarjetas"
                  }}
                >
                  {mascotas.map((mascota) => (
                    <div
                      className="custom-card"
                      key={mascota.id}
                      style={{
                        position: 'relative',
                        borderRadius: '12px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        backgroundImage: `url(${mascota.foto || '/uploads/default-avatar.png'})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '200px',
                        color: 'white',
                      }}
                    >
                      {/* Contenido superpuesto en la tarjeta */}
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '10px',
                          left: '10px',
                          right: '10px',
                          background: 'rgba(0, 0, 0, 0.5)',
                          borderRadius: '8px',
                          padding: '10px',
                        }}
                      >
                        <IonLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          Nombre: {mascota.nombre}
                        </IonLabel>
                        <br />
                        <IonLabel style={{ fontSize: '12px' }}>
                          Tipo: {mascota.tipo}
                        </IonLabel>
                        <br />
                        <IonLabel style={{ fontSize: '12px' }}>
                          Descripción: {mascota.descripcion}
                        </IonLabel>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Botón de añadir debajo de las mascotas */}
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                  <IonButton color="success" onClick={() => history.push('/add-pet')}>
                    Añadir
                  </IonButton>
                </div>
              </>
            ) : (
              <>
                <IonLabel>No tienes mascotas registradas.</IonLabel>
                {/* Botón de añadir si no hay mascotas */}
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                  <IonButton color="success" onClick={() => history.push('/add-pet')}>
                    Añadir
                  </IonButton>
                </div>
              </>
            )}
          </div>


  
        <div style={{ marginTop: '40px', marginLeft: '25px' }}>
          <IonLabel><strong>Mis Tarjetas</strong></IonLabel>
        </div>
  
        <div className="card-container" style={{ marginLeft: '40px' }}>
          {tarjetas.length > 0 ? (
            tarjetas.map((tarjeta) => (
              <div
                className="custom-card"
                key={tarjeta.id}
                style={{ backgroundImage: `url(${tarjeta.imageUrl})` }}
              >
                {/* Botón para eliminar tarjeta */}
                <IonFabButton
                  color="danger"
                  size="small" // Reduce el tamaño del botón
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    zIndex: 10, // Asegura que esté sobre otros elementos
                    width: '35px', // Ajusta manualmente el tamaño si es necesario
                    height: '35px',
                  }}
                  onClick={async () => {
                    try {
                      const response = await axios.delete(
                        `https://misumisu.eu/tarjetas/${tarjeta.id}`,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      );
                      if (response.status === 200) {
                        // Eliminar tarjeta del estado local
                        setTarjetas((prevTarjetas) =>
                          prevTarjetas.filter((t) => t.id !== tarjeta.id)
                        );
                        alert('Tarjeta eliminada exitosamente.');
                      }
                    } catch (error) {
                      console.error('Error al eliminar la tarjeta:', error);
                      alert('No se pudo eliminar la tarjeta.');
                    }
                  }}
                >
                  <IonIcon icon="remove-circle" /> {/* Icono de signo menos */}
                </IonFabButton>

                <div
                  className="card-type"
                  style={{
                    backgroundColor:
                      tarjeta.location === 'home' ? '#FF5ECC' : '#2196F3',
                  }}
                >
                  {tarjeta.location === 'home' ? 'En casa' : 'Fuera'}
                </div>

                <div className="card-date">
                  {new Date(tarjeta.deadline).toLocaleDateString()}
                </div>

                <div className="card-content">
                  <div className="card-header">
                    <IonCardTitle>{tarjeta.title}</IonCardTitle>
                  </div>
                  <p>{tarjeta.content}</p>
                </div>
              </div>
            ))
          ) : (
            <IonLabel>No tienes tarjetas registradas.</IonLabel>
          )}
        </div>

        {/* Botón para volver a Inicio */}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <IonButton
            color="secondary"
            onClick={() => history.push('/home')} // Redirección a la página de inicio
          >
            Volver a Inicio
          </IonButton>
          <IonButton
            color="secondary"
            onClick={() => history.push('/user-profile')} 
          >
            Editar perfil
          </IonButton>
          <IonButton
            color="danger"
            onClick={async () => {
              try {
                // Llama a la API de eliminación
                const response = await axios.delete(`https://misumisu.eu/usuarios/${ownerId}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });

                // Verifica el estado de la respuesta
                if (response.status === 200) {
                  alert('Perfil eliminado correctamente.');
                  // Limpia el localStorage y redirige a la página de inicio
                  localStorage.clear();
                  history.push('/home');
                } else {
                  alert('No se pudo eliminar el perfil. Inténtalo nuevamente.');
                }
              } catch (error) {
                console.error('Error al eliminar el perfil:', error);
                alert('Ocurrió un error al intentar eliminar el perfil.');
              }
            }}
          >
            Borrar perfil
        </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
  
};

export default MyInterface;
