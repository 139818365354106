import { useEffect, useState, useRef } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonLabel,
  IonButton,
  IonSpinner,
  IonIcon,
  IonInput,
} from '@ionic/react';
import { pencil, checkmark, close } from 'ionicons/icons';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const PetProfile: React.FC = () => {
  const { petId } = useParams<{ petId: string }>();
  const token = localStorage.getItem('token');
  const ownerId = localStorage.getItem('id');
  const [pet, setPet] = useState<any>({ nombre: '', tipo: '', descripcion: '', foto: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingField, setEditingField] = useState<string | null>(null);

  const inputRefs = useRef<{ [key: string]: HTMLIonInputElement | null }>({});

  useEffect(() => {
    const fetchPetProfile = async () => {
      if (token && ownerId && petId) {
        try {
          const response = await axios.get(
            `https://misumisu.eu/mascotas/owner/${ownerId}/pet/${petId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setPet(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error al obtener los datos de la mascota:', error);
          setError('No se pudo cargar la información de la mascota.');
          setLoading(false);
        }
      } else {
        setError('Faltan parámetros o token para cargar los datos.');
        setLoading(false);
      }
    };

    fetchPetProfile();
  }, [token, ownerId, petId]);

  const handleEditClick = (field: string) => {
    setEditingField(field);
    if (inputRefs.current[field]) {
      inputRefs.current[field]!.setFocus();
    }
  };

  const handleSaveClick = async () => {
    try {
      const updatedField = { [editingField!]: pet[editingField!] };
      await axios.put(
        `https://misumisu.eu/mascotas/${petId}`,
        updatedField,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setEditingField(null);
    } catch (error) {
      console.error('Error al actualizar los datos de la mascota:', error);
      setError('No se pudieron guardar los cambios.');
    }
  };

  const handleCancelEdit = () => {
    setEditingField(null);
  };

  const handleInputChange = (field: string, value: string) => {
    setPet({ ...pet, [field]: value });
  };

  if (loading) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Perfil de la Mascota</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <IonSpinner name="crescent" />
          </div>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Perfil de la Mascota</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {error ? (
          <p>{error}</p>
        ) : (
          pet && (
            <IonCard style={{ padding: '20px' }}>
              <IonCardContent>
                {['nombre', 'tipo', 'descripcion'].map((field) => (
                  <div
                    key={field}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 2fr auto',
                      alignItems: 'center',
                      gap: '10px',
                      marginBottom: '15px',
                    }}
                  >
                    <IonLabel>
                      <strong>
                        {field.charAt(0).toUpperCase() + field.slice(1)}:
                      </strong>
                    </IonLabel>
                    {editingField === field ? (
                      <IonInput
                        ref={(el) => {
                          inputRefs.current[field] = el;
                        }}
                        value={pet[field]}
                        onIonChange={(e) =>
                          handleInputChange(field, e.detail.value!)
                        }
                        style={{
                          borderBottom: '2px solid blue',
                          paddingBottom: '4px',
                        }}
                      />
                    ) : (
                      <IonLabel>{pet[field]}</IonLabel>
                    )}
                    <div style={{ display: 'flex', gap: '5px' }}>
                      {editingField === field ? (
                        <>
                          <IonButton
                            fill="clear"
                            size="small"
                            onClick={handleSaveClick}
                          >
                            <IonIcon icon={checkmark} />
                          </IonButton>
                          <IonButton
                            fill="clear"
                            size="small"
                            onClick={handleCancelEdit}
                          >
                            <IonIcon icon={close} />
                          </IonButton>
                        </>
                      ) : (
                        <IonButton
                          fill="clear"
                          size="small"
                          onClick={() => handleEditClick(field)}
                        >
                          <IonIcon icon={pencil} />
                        </IonButton>
                      )}
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 2fr',
                    alignItems: 'center',
                    gap: '10px',
                    marginBottom: '15px',
                  }}
                >
                  <IonLabel>
                    <strong>Foto:</strong>
                  </IonLabel>
                  {pet.foto ? (
                    <img
                      src={pet.foto}
                      alt="Mascota"
                      style={{ maxWidth: '100px', maxHeight: '100px' }}
                    />
                  ) : (
                    <IonLabel>No hay foto disponible</IonLabel>
                  )}
                </div>
                <IonButton
                  expand="full"
                  color="primary"
                  href="/home"
                  style={{ marginTop: '20px' }}
                >
                  Volver al Inicio
                </IonButton>
              </IonCardContent>
            </IonCard>
          )
        )}
      </IonContent>
    </IonPage>
  );
};

export default PetProfile;
