import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonSpinner,
  IonImg,
} from '@ionic/react';
import { useParams } from 'react-router-dom';

interface UserProfile {
  id: string;
  nombre: string;
  apellido1: string;
  apellido2: string;
  email: string;
  direccion: string;
  Foto?: string;  // Propiedad para la imagen base64
}

const CardProfile: React.FC = () => {
  const { ownerId } = useParams<{ ownerId: string }>(); // Obtiene el ID del propietario desde la URL
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`https://misumisu.eu/usuarios/${ownerId}`);
        if (!response.ok) {
          throw new Error('Error al obtener el perfil de usuario');
        }
        const data = await response.json();
        setUserProfile(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [ownerId]);

  if (loading) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Cargando...</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonSpinner />
        </IonContent>
      </IonPage>
    );
  }

  if (!userProfile) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Error</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <p>No se pudo cargar el perfil del usuario. Por favor, inténtelo más tarde.</p>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Perfil de Usuario</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard>
          {/* Mostrar la imagen de perfil */}
          {userProfile.Foto && (
            <IonImg
              src={userProfile.Foto} // Asignamos la URL de la imagen aquí
              alt="Imagen de perfil"
              style={{
                borderRadius: '50%',  // Forma circular
                width: '150px',       // Tamaño de la imagen
                height: '150px',      // Tamaño de la imagen
                margin: '20px auto',  // Centrado
                display: 'block'      // Asegura que sea un bloque para centrarla
              }}
            />
          )}

          <IonCardContent>
            <p><strong>Nombre:</strong> {userProfile.nombre}</p>
            <p><strong>Apellido1:</strong> {userProfile.apellido1}</p>
            <p><strong>Apellido2:</strong> {userProfile.apellido2}</p>
            <p><strong>Dirección:</strong> {userProfile.direccion}</p>
            <p><strong>Email:</strong> {userProfile.email}</p>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default CardProfile;
