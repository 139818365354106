import React, { useState } from "react";
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonToast,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useHistory } from "react-router-dom"; // Importa useHistory para redirigir

const AddPet: React.FC = () => {
  const history = useHistory();
  const [pets, setPets] = useState([{ nombre: "", tipo_mascota: "", foto: null, descripcion: "" }]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  const addNewPet = () => {
    setPets([...pets, { nombre: "", tipo_mascota: "", foto: null, descripcion: "" }]);
  };

  const handlePetChange = (index: number, field: string, value: any) => {
    const newPets = [...pets];
    newPets[index] = { ...newPets[index], [field]: value };
    setPets(newPets);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const email1 = localStorage.getItem("userEmail");
    const email = localStorage.getItem("email");

    // Validar si al menos uno de los emails está presente
    if (!email && !email1) {
      console.error("Error: No se encontró el email del usuario en localStorage.");
      setToastMessage("Error: No se encontró el email del usuario.");
      setShowToast(true);
      return;
    }

    // Prioriza `email1` si existe, de lo contrario usa `email`, y asegúrate de que no sea `null`
    const selectedEmail = (email1 || email || "").toString();

    const formData = new FormData();
    formData.append("email", selectedEmail); // Agrega el email del dueño

    // Convierte el array de mascotas a JSON string y lo añade a `FormData`
    formData.append('pets', JSON.stringify(pets)); 

    // Añadir cada archivo de imagen (si existe) al `FormData` con el nombre `foto`
    pets.forEach((pet) => {
      if (pet.foto) {
        formData.append('foto', pet.foto); // Subir cada archivo de imagen con el mismo campo `foto`
      }
    });

    try {
      const response = await fetch("https://misumisu.eu/add-pet2", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Error al guardar las mascotas.");
      }

      const result = await response.json();
      console.log("Respuesta del servidor:", result);
      setToastMessage("Mascotas guardadas exitosamente.");
      setShowToast(true);
      history.push("/home");
    } catch (error) {
      console.error("Error al guardar las mascotas:", error);
      setToastMessage("Hubo un error al guardar las mascotas.");
      setShowToast(true);
    }
  };

  const handleNoPets = () => {
    history.push('/home');
  };

  return (
    <IonPage>
      <IonContent style={styles.content}>
        <div style={styles.cardContainer}>
          <IonCard style={styles.card}>
            <IonCardHeader>
              <IonCardTitle>Añadir Mascotas</IonCardTitle>
            </IonCardHeader>
            <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
              {pets.map((pet, index) => (
                <div key={index} style={styles.petCard}>
                  <IonItem>
                    <IonLabel position="floating">Nombre de la Mascota</IonLabel>
                    <IonInput
                      type="text"
                      value={pet.nombre}
                      onIonChange={(e: any) => handlePetChange(index, "nombre", e.target.value)}
                      required
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel>Tipo de Mascota</IonLabel>
                    <IonSelect
                      value={pet.tipo_mascota}
                      onIonChange={(e: any) => handlePetChange(index, "tipo_mascota", e.detail.value)}
                      placeholder="Selecciona el tipo de mascota"
                    >
                      <IonSelectOption value="perro">Perro</IonSelectOption>
                      <IonSelectOption value="gato">Gato</IonSelectOption>
                      <IonSelectOption value="ave">Ave</IonSelectOption>
                      <IonSelectOption value="otros">Otros</IonSelectOption>
                    </IonSelect>
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked">Foto de la Mascota (Opcional)</IonLabel>
                    <div style={styles.imageContainer}>
                      {pet.foto && (
                        <img
                          src={typeof pet.foto === 'string' ? pet.foto : URL.createObjectURL(pet.foto)}
                          alt="Previsualización de la mascota"
                          style={styles.image}
                        />
                      )}
                    </div>
                    <input
                      type="file"
                      onChange={(e) => {
                        const files = e.target.files;
                        if (files && files.length > 0) {
                          handlePetChange(index, "foto", files[0]);
                        }
                      }}
                      accept="image/*"
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel position="floating">Descripción</IonLabel>
                    <IonInput
                      type="text"
                      value={pet.descripcion}
                      onIonChange={(e: any) => handlePetChange(index, "descripcion", e.target.value)}
                    />
                  </IonItem>
                </div>
              ))}
              <IonButton
                onClick={addNewPet}
                expand="full"
                style={{ marginTop: "10px" }}
                color="secondary"
              >
                Añadir Otra Mascota
              </IonButton>
              <IonButton
                type="submit"
                expand="full"
                style={{ marginTop: "20px" }}
                color="primary"
              >
                Guardar Mascotas
              </IonButton>
              <IonButton
                expand="full"
                style={{ marginTop: "10px" }}
                color="medium"
                onClick={handleNoPets}
              >
                No tengo una mascota
              </IonButton>
            </form>
          </IonCard>
        </div>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage || undefined}
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};

const styles = {
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f5f5dc',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
  },
  card: {
    width: '90%',
    maxWidth: '400px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  petCard: {
    marginBottom: '20px',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    overflow: 'hidden',
    margin: '10px auto',
    border: '2px solid #ccc',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover' as React.CSSProperties['objectFit'], // Corregir el tipo con "as"
  },
};

export default AddPet;
