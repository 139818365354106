import { Route, Switch, Redirect } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Registro';
import CompleteProfile from './pages/CompleteProfile';
import AddPet from './pages/AddPet';
import CardDetail from './pages/CardDetail';
import UserProfile from './pages/UserProfile';
import PetProfile from './pages/PetProfile';
import CartProfile from './pages/CartProfile';
import CartPetProfile from './pages/CartPetProfile';
import MyInterface from './pages/MyInterface';
import HerInterface from './pages/HerInterface';
import MessagesInbox from './pages/MessagesInbox';

import { useEffect, useState } from 'react';
import { cerrarSesion } from './auth';
import history from './history';

import '@ionic/react/css/core.css';
import './theme/variables.css';

setupIonicReact();

const ProtectedRoute: React.FC<{
  component: React.ComponentType<any>;
  isAuthenticated: boolean;
  redirectTo: string;
  [x: string]: any; // Aceptar props adicionales
}> = ({ component: Component, isAuthenticated, redirectTo, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to={redirectTo} />
      )
    }
  />
);


const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    return !!localStorage.getItem('isAuthenticated');
  });

  const [isProfileComplete, setIsProfileComplete] = useState<boolean>(() => {
    return !!localStorage.getItem('isProfileComplete');
  });

  useEffect(() => {
    try {
      const handleStorageChange = () => {
        console.log("Sincronizando estado con localStorage...");
        setIsAuthenticated(!!localStorage.getItem("isAuthenticated"));
        setIsProfileComplete(!!localStorage.getItem("isProfileComplete"));
      };
  
      window.addEventListener("storage", handleStorageChange);
      handleStorageChange(); // Llama esta función al montar el componente
      return () => window.removeEventListener("storage", handleStorageChange);
    } catch (error) {
      console.error("Error en useEffect:", error);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && isProfileComplete) {
      console.log("El perfil está completo, no es necesario redirigir automáticamente.");
    }
  }, [isAuthenticated, isProfileComplete]);
  

  const handleLogout = () => {
    cerrarSesion();
    localStorage.clear();
    setIsAuthenticated(false);
    setIsProfileComplete(false);
    history.push('/home'); // Redirigir a Home después de cerrar sesión
  };

  const handleLogin = (isNewUser: boolean) => {
    setIsAuthenticated(true);
    if (isNewUser) {
      setIsProfileComplete(false);
      localStorage.setItem('isProfileComplete', 'false');
    } else {
      setIsProfileComplete(true);
      localStorage.setItem('isProfileComplete', 'true');
    }
  };

  const handleProfileComplete = () => {
    setIsProfileComplete(true);
    localStorage.setItem("isProfileComplete", "true");
    window.location.href = "/add-pet"; // Redirige correctamente
  };
  
  

  return (
    <IonApp>
  <IonReactRouter history={history}>
    <IonRouterOutlet>
      <Switch>
        {/* Página de inicio */}
        <Route exact path="/">
          <Home onLogout={handleLogout} isAuthenticated={isAuthenticated} />
        </Route>


        {/* Página de inicio de sesión */}
        <Route exact path="/login">
          {isAuthenticated ? (
            !isProfileComplete ? (
              <Redirect to="/complete-profile" />
            ) : (
              <Redirect to="/" />
            )
          ) : (
            <Login onLogin={handleLogin} />
          )}
        </Route>



        {/* Registro */}
        <Route exact path="/register">
          <Register onRegister={() => handleLogin(true)} />
        </Route>

        {/* Completar Perfil */}
        <ProtectedRoute
          exact
          path="/complete-profile"
          isAuthenticated={isAuthenticated && !isProfileComplete}
          redirectTo="/add-pet"
          component={() => <CompleteProfile onCompleteProfile={handleProfileComplete} />}
        />

        {/* Agregar Mascota */}
        <ProtectedRoute
          exact
          path="/add-pet"
          isAuthenticated={isAuthenticated && isProfileComplete}
          redirectTo="/complete-profile"
          component={AddPet}
        />


        {/* User Profile */}
        <ProtectedRoute
          exact
          path="/user-profile"
          isAuthenticated={isAuthenticated}
          redirectTo="/login"
          component={UserProfile}
        />

        {/* Pet Profile */}
        <ProtectedRoute
          exact
          path="/pet-profile/:petId"
          isAuthenticated={isAuthenticated}
          redirectTo="/login"
          component={PetProfile}
        />

        {/* Cart Profile */}
        <ProtectedRoute
          exact
          path="/profileCart/:id"
          isAuthenticated={isAuthenticated}
          redirectTo="/login"
          component={CartProfile}
        />

        {/* Cart Pet Profile */}
        <ProtectedRoute
          exact
          path="/mascotas/:id"
          isAuthenticated={isAuthenticated}
          redirectTo="/login"
          component={CartPetProfile}
        />

        {/* My Interface */}
        <ProtectedRoute
        exact
        path="/my-interface"
        isAuthenticated={isAuthenticated} // Permite acceso solo si está autenticado
        redirectTo="/login" // Redirige al login si no está autenticado
        component={MyInterface}
        />

        {/* Her Interface */}
        <ProtectedRoute
        exact
        path="/usuarios/:ownerId"
        isAuthenticated={isAuthenticated} // Permite acceso solo si está autenticado {/* Her Interface */}
        redirectTo="/login" // Redirige al login si no está autenticado <Route exact path="/usuarios/:ownerId" component={HerInterface} />
        component={HerInterface}
        />

        {/* Messages Inbox */}
        <ProtectedRoute
          exact
          path="/messages-inbox"
          isAuthenticated={isAuthenticated}
          redirectTo="/login"
          component={MessagesInbox}
        />

        {/* Ruta por defecto */}
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </IonRouterOutlet>
  </IonReactRouter>
</IonApp>

  );
};

export default App;
