import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonImg,
  IonButton,
  IonLabel,
  IonCardTitle,
  IonSpinner,
} from '@ionic/react';
import { useParams, useHistory } from 'react-router-dom';
import './MyInterface.css'; // Mantenemos los estilos de MyInterface

interface UserProfile {
  username: string; // Solo se necesita el nombre de usuario
  profileImage: string | null; // Propiedad para la imagen de perfil
}

interface Mascota {
  id: string;
  nombre: string;
  tipo: string;
  descripcion: string;
  foto?: string;
}

interface Card {
  id: number;
  title: string;
  content: string;
  imageUrl: string;
  deadline: string;
  location: string;
}

const HerInterface: React.FC = () => {
  const { ownerId } = useParams<{ ownerId: string }>(); // Obtener el ID del propietario desde la URL
  console.log('ownerId:', ownerId);
  const [userProfile, setUserData] = useState<UserProfile | null>(null);
  const [mascotas, setMascotas] = useState<Mascota[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [cards, setCards] = useState<Card[]>([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://misumisu.eu/usuarios/${ownerId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          if (response.status === 401) {
            throw new Error('No autorizado. Por favor, inicie sesión nuevamente.');
          }
          throw new Error('Error al obtener el perfil del usuario');
        }
        const data = await response.json();
        setUserData({
          username: data.username,
          profileImage: data.profileImage || 'default-avatar.png',
        });
      } catch (error) {
        console.error(error);
        setError('Error al obtener el perfil del usuario');
      }
    };

    const fetchMascotas = async () => {
      try {
        const response = await fetch(`https://misumisu.eu/mascotas2/owner/${ownerId}`);
        if (response.status === 404) {
          // Si no hay mascotas, simplemente deja el array vacío y no marques un error
          setMascotas([]);
          return;
        }
        if (!response.ok) {
          throw new Error('Error al obtener las mascotas');
        }
        const data = await response.json();
        setMascotas(data);
      } catch (error) {
        console.error(error);
        // Establece un error solo en caso de problemas reales, no cuando el usuario no tiene mascotas
        setError('Error al obtener las mascotas');
      }
    };


    const fetchCards = async () => {
      try {
        const response = await fetch(`https://misumisu.eu/tarjetas/usuario/${ownerId}`);
        if (!response.ok) {
          throw new Error('Error al obtener las tarjetas');
        }
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error(error);
        setError('Error al obtener las tarjetas');
      }
    };

    Promise.all([fetchUserProfile(), fetchMascotas(), fetchCards()]).finally(() =>
      setLoading(false)
    );
  }, [ownerId]);

  if (loading) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Cargando...</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSpinner />
        </IonContent>
      </IonPage>
    );
  }

  if (!userProfile) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Error</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <p>No se pudo cargar el perfil del usuario. Por favor, inténtelo más tarde.</p>
          <IonButton expand="block" onClick={() => history.push('/home')}>
            Volver a Inicio
          </IonButton>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Perfil del Usuario</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
  {error && mascotas.length === 0 && <IonLabel>{error}</IonLabel>} {/* Mostrar error solo si hay un error real */}

  {userProfile.profileImage && (
    <div className="profile-wrapper">
      <img
        src={userProfile.profileImage}
        alt="Foto de perfil"
        className="profile-image"
        onError={(e) => (e.currentTarget.src = 'default-avatar.png')}
      />
    </div>
  )}

  {userProfile && (
    <div className="user-info">
      <IonLabel className="profile-name">{userProfile.username}</IonLabel>
    </div>
  )}

<div style={{ marginTop: '30px', marginLeft: '25px' }}>
            <IonLabel>
              <strong>Sus Mascotas</strong>
            </IonLabel>
            {mascotas.length > 0 ? (
              <>
                <div
                  className="card-container"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', // Igual tamaño que las tarjetas
                    gap: '20px', // Espaciado entre tarjetas
                    marginTop: '20px',
                    marginLeft: '25px', // Margen izquierdo igual que "Mis Tarjetas"
                  }}
                >
                  {mascotas.map((mascota) => (
                    <div
                      className="custom-card"
                      key={mascota.id}
                      style={{
                        position: 'relative',
                        borderRadius: '12px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        backgroundImage: `url(${mascota.foto || '/uploads/default-avatar.png'})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '200px',
                        color: 'white',
                      }}
                    >
                      {/* Contenido superpuesto en la tarjeta */}
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '10px',
                          left: '10px',
                          right: '10px',
                          background: 'rgba(0, 0, 0, 0.5)',
                          borderRadius: '8px',
                          padding: '10px',
                        }}
                      >
                        <IonLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          Nombre: {mascota.nombre}
                        </IonLabel>
                        <br />
                        <IonLabel style={{ fontSize: '12px' }}>
                          Tipo: {mascota.tipo}
                        </IonLabel>
                        <br />
                        <IonLabel style={{ fontSize: '12px' }}>
                          Descripción: {mascota.descripcion}
                        </IonLabel>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Botón de añadir debajo de las mascotas */}
                
              </>
            ) : (
              <>
                <IonLabel>No tienes mascotas registradas.</IonLabel>
                {/* Botón de añadir si no hay mascotas */}
              </>
            )}
          </div>


  <div style={{ marginTop: '40px', marginLeft: '25px' }}>
    <IonLabel>
      <strong>Sus Tarjetas</strong>
    </IonLabel>
  </div>

  <div className="card-container" style={{ marginLeft: '25px' }}>
    {cards.length > 0 ? (
      cards.map((card) => (
        <div
          className="custom-card"
          key={card.id}
          style={{ backgroundImage: `url(${card.imageUrl})` }}
        >
          <div
            className="card-type"
            style={{
              backgroundColor: card.location === 'home' ? '#FF5ECC' : '#2196F3',
            }}
          >
            {card.location === 'home' ? 'En casa' : 'Fuera'}
          </div>
          <div className="card-date">{new Date(card.deadline).toLocaleDateString()}</div>
          <div className="card-content">
            <IonCardTitle>{card.title}</IonCardTitle>
            <p>{card.content}</p>
          </div>
        </div>
      ))
    ) : (
      <IonLabel>No hay tarjetas registradas.</IonLabel>
    )}
  </div>

  {/* Botón para volver a Inicio */}
  <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <IonButton
            color="secondary"
            onClick={() => history.push('/home')} // Redirección a la página de inicio
          >
            Volver a Inicio
          </IonButton>
        </div>
</IonContent>

    </IonPage>
  );
};

export default HerInterface;
