import React, { useState } from "react";
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonToast
} from "@ionic/react";
import { useHistory } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Asegúrate de importar 'auth'
import { createUserWithEmailAndPassword } from "firebase/auth";

interface RegisterProps {
  onRegister: () => void;
}

const Register: React.FC<RegisterProps> = ({ onRegister }) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showToast, setShowToast] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Las contraseñas no coinciden.");
      setShowToast(true);
      return;
    }
  
    try {
      // Utiliza Firebase para registrar al usuario
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const firebaseUID = userCredential.user.uid;
      console.log("Usuario registrado con Firebase:", userCredential.user);
  
      // Enviar información del usuario al backend para registrarlo en la base de datos
      const response = await fetch("https://misumisu.eu/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, firebaseUID, password }), // Incluye la contraseña
      });
  
      // Imprimir la respuesta del servidor para verificar qué está pasando
      const responseData = await response.json();
      console.log("Respuesta del servidor:", responseData);
  
      if (!response.ok) {
        throw new Error(`Error al registrar el usuario en la base de datos: ${responseData.message}`);
      }
  
      console.log("Usuario registrado en la base de datos.");
      
      // Guardar el token y otros datos en localStorage
      const token = responseData.token; // Asegúrate de que el token venga en la respuesta
      if (token) {
        localStorage.setItem("token", token);
        console.log("Token guardado en localStorage:", token);
      }
      // Guardar el Firebase UID y el estado de autenticación en el localStorage
      localStorage.setItem("userEmail", email);
      localStorage.setItem("firebaseUID", firebaseUID);// Asegúrate de guardar el UID de Firebase
      localStorage.setItem("id", firebaseUID);
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("isProfileComplete", "false");
        
      // Llamar la función onRegister para actualizar el estado de autenticación global
      onRegister();
  
      console.log("Redirigiendo a /complete-profile...");
      console.log("localStorage.isAuthenticated:", localStorage.getItem("isAuthenticated"));
      console.log("localStorage.isProfileComplete:", localStorage.getItem("isProfileComplete"));
      onRegister(); // Actualiza estados globales
      history.push("/complete-profile"); // Redirige al perfil

          
    } catch (error) {
      console.error("Error al registrar el usuario:", error);
      setErrorMessage("Hubo un error al registrar el usuario.");
      setShowToast(true);
    }
  };
  

  return (
    <IonPage>
      <IonContent style={styles.content}>
        <div style={styles.cardContainer}>
          <IonCard style={styles.card}>
            <IonCardHeader>
              <IonCardTitle>Registrar Usuario</IonCardTitle>
            </IonCardHeader>
            <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
              {errorMessage && (
                <IonToast
                  isOpen={showToast}
                  onDidDismiss={() => setShowToast(false)}
                  message={errorMessage}
                  duration={2000}
                />
              )}
              <IonItem>
                <IonLabel position="floating">Correo Electrónico</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  onIonChange={(e: any) => setEmail(e.target.value)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Contraseña</IonLabel>
                <IonInput
                  type="password"
                  value={password}
                  onIonChange={(e: any) => setPassword(e.target.value)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Repetir Contraseña</IonLabel>
                <IonInput
                  type="password"
                  value={confirmPassword}
                  onIonChange={(e: any) => setConfirmPassword(e.target.value)}
                  required
                />
              </IonItem>
              <IonButton
                type="submit"
                expand="full"
                style={{ marginTop: "20px" }}
                color="primary"
              >
                Registrar
              </IonButton>
            </form>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

const styles = {
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f5f5dc', 
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
  },
  card: {
    width: '90%',
    maxWidth: '400px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
};

export default Register;
