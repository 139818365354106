import React from 'react';
import ReactDOM from 'react-dom/client'; // Importa createRoot desde react-dom/client
import './index.css';
import App from './App.tsx';

// Encuentra el contenedor raíz en el DOM
const rootElement = document.getElementById('root');

// Crea la raíz utilizando createRoot
const root = ReactDOM.createRoot(rootElement);

// Renderiza tu aplicación con el nuevo API
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
