import React, { useState } from 'react';
import {
  IonPage,
  IonContent,
  IonInput,
  IonButton,
  IonItem,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonToast,
  IonAlert,
} from '@ionic/react';
import { signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import { auth, googleProvider } from '../firebaseConfig';
import { logoGoogle } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

interface LoginProps {
  onLogin: (isNewUser: boolean) => void;
}

interface LoginResponse {
  message: string;
  user: {
    id: string;
    email: string;
    username: string;
  };
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);

  const loginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
  
      if (!user || !user.email) {
        throw new Error('No se pudo obtener el correo electrónico de la cuenta de Google.');
      }
  
      const userData = {
        email: user.email,
        firebaseUID: user.uid,
      };
  
      const response = await axios.post<{ token: string; user: LoginResponse['user'] }>(
        'https://misumisu.eu/register-google',
        userData
      );
  
      // Caso de usuario nuevo
      if (response.status === 201) {
        localStorage.setItem('userEmail', response.data.user.email);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('isProfileComplete', 'false');
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('id', response.data.user.id);
        localStorage.setItem('firebaseUID', response.data.user.id);
        onLogin(true);
        history.push('/complete-profile');
      } else if (response.status === 202){
        // Caso de usuario existente
        const token = response.data.token;
        const user = response.data.user;
  
        // Guardar los datos en localStorage
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('isProfileComplete', 'true');
        localStorage.setItem('token', token);
        localStorage.setItem('id', user.id);
        localStorage.setItem('firebaseUID', user.id);
        localStorage.setItem('email', user.email);
        localStorage.setItem('username', user.username || '');
        
        // Redirigir al usuario al inicio
        onLogin(false);
        history.push('/home');
      }
    } catch (error: any) {
      
      // Otros errores
      console.error('Error al iniciar sesión con Google:', error);
      setToastMessage('Error al iniciar sesión con Google.');
      setShowToast(true);
      
    }
  };
  

  const loginWithEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post<{ token: string; user: LoginResponse['user'] }>('https://misumisu.eu/login', { email, password });

      if (response.data && response.data.user) {
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('isProfileComplete', 'true');
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userEmail', response.data.user.email);
        localStorage.setItem('id', response.data.user.id);
        localStorage.setItem('firebaseUID', response.data.user.id);
        localStorage.setItem('username', response.data.user.username);
        onLogin(false);
        history.push('/home');
      }
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        history.push('/register');
      } else if (err.response && err.response.status === 401) {
        setToastMessage('Contraseña incorrecta.');
        setShowToast(true);
      } else {
        setToastMessage('Error al iniciar sesión.');
        setShowToast(true);
      }
    }
  };

  const resetPassword = async () => {
    if (!email) {
      setToastMessage('Por favor, introduce tu correo electrónico.');
      setShowToast(true);
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setToastMessage('Se ha enviado un correo electrónico para restablecer la contraseña.');
      setShowToast(true);
      setShowResetPassword(false);
    } catch (error) {
      console.error('Error al enviar el correo de restablecimiento:', error);
      setToastMessage('Error al enviar el correo de restablecimiento.');
      setShowToast(true);
    }
  };

  return (
    <IonPage>
      <IonContent style={styles.content}>
        <div style={styles.cardContainer}>
          <IonCard style={styles.card}>
            <IonCardHeader>
              <IonCardTitle>Iniciar Sesión</IonCardTitle>
            </IonCardHeader>
            <div style={{ padding: '20px' }}>
              <IonButton expand="full" onClick={loginWithGoogle}>
                <IonIcon slot="start" icon={logoGoogle}></IonIcon>
                Iniciar sesión con Google
              </IonButton>

              <div style={{ textAlign: 'center', margin: '20px 0' }}>O</div>

              <form onSubmit={loginWithEmail}>
                <IonItem>
                  <IonLabel position="floating">Correo Electrónico</IonLabel>
                  <IonInput
                    type="email"
                    value={email}
                    onIonInput={(e: any) => setEmail(e.target.value)}
                    required
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Contraseña</IonLabel>
                  <IonInput
                    type="password"
                    value={password}
                    onIonInput={(e: any) => setPassword(e.target.value)}
                    required
                  />
                </IonItem>

                <IonButton expand="full" type="submit" style={{ marginTop: '20px' }}>
                  Iniciar sesión
                </IonButton>
              </form>

              <IonButton
                expand="full"
                onClick={() => setShowResetPassword(true)}
                style={{ marginTop: '10px' }}
                color="light"
              >
                ¿Olvidaste tu contraseña?
              </IonButton>

              <IonButton
                expand="full"
                routerLink="/register"
                style={{ marginTop: '20px' }}
                color="primary"
              >
                Registrarse
              </IonButton>
            </div>
          </IonCard>
        </div>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={2000}
        />

        <IonAlert
          isOpen={showResetPassword}
          onDidDismiss={() => setShowResetPassword(false)}
          header="Restablecer Contraseña"
          subHeader="Introduce tu correo electrónico"
          inputs={[
            {
              name: 'email',
              type: 'email',
              placeholder: 'Correo Electrónico',
              value: email,
            },
          ]}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Enviar',
              handler: (input) => {
                if (input.email) {
                  setEmail(input.email);
                  resetPassword();
                }
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

const styles = {
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f5f5dc',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
  },
  card: {
    width: '90%',
    maxWidth: '400px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
};

export default Login;
