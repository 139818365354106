import React, { useEffect, useState } from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonCard, IonSpinner, IonImg, IonLabel } from '@ionic/react';
import { useParams } from 'react-router-dom';

interface CartPetProfile {
  id: string;
  id_owner: string;
  nombre: string;
  tipo_mascota: string;
  descripcion: string;
  foto?: string; // Propiedad opcional para la foto de la mascota
}

const CartPetProfile: React.FC = () => {
  const { ownerId } = useParams<{ ownerId: string }>(); // Obtiene el ID del propietario desde la URL
  const [pets, setPets] = useState<CartPetProfile[]>([]); // Cambié a un array de mascotas
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPetsByOwner = async () => {
      try {
        const response = await fetch(`https://misumisu.eu/mascotas/owner/${ownerId}`);
        if (!response.ok) {
          throw new Error('Error al obtener las mascotas');
        }
        const data = await response.json();
        setPets(data);
      } catch (error) {
        console.error('Error al cargar las mascotas:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPetsByOwner();
  }, [ownerId]);

  if (loading) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Cargando...</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonSpinner />
        </IonContent>
      </IonPage>
    );
  }

  if (pets.length === 0) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Error</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <p>Este propietario no teiene mascotas agregadas.</p>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Perfil de Mascotas</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {pets.map((pet) => (
          <IonCard key={pet.id} style={{ padding: '20px', marginBottom: '20px' }}>
          {/* Foto de la mascota, arriba y más grande */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            {pet.foto ? (
              <IonImg
                src={pet.foto}
                alt={`${pet.nombre} mascota`}
                style={{
                  width: '100%', // Foto ocupa todo el ancho disponible
                  height: 'auto',
                  maxWidth: '400px', // Tamaño máximo de la imagen
                  borderRadius: '10px', // Bordes redondeados
                  margin: '0 auto', // Centrado de la imagen
                }}
              />
            ) : (
              <IonLabel>No hay foto</IonLabel>
            )}
          </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p><strong>Nombre:</strong> {pet.nombre}</p>
              <p><strong>Tipo:</strong> {pet.tipo_mascota}</p>
              <p><strong>Descripción:</strong> {pet.descripcion}</p>
            </div>
          </IonCard>
        ))}
      </IonContent>
    </IonPage>
  );
};

export default CartPetProfile;
